import Alpine from 'alpinejs';

// Components
import Swiper from 'swiper';
import { Autoplay, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

Fancybox.bind("[data-fancybox]", {
    // Your custom options
});

const swiperHero = new Swiper('.swiper-hero-slider', {
    // Optional parameters
    direction: 'horizontal',
    loop: true,
    modules: [Autoplay, Navigation],
    autoplay: {
        delay: 4000,
        pauseOnMouseEnter: true
    },
    navigation: {
        nextEl: '.swiper-hero-button-next',
        prevEl: '.swiper-hero-button-prev',
    },
});

const swiperContentGallery = new Swiper('.swiper-content-gallery', {
    direction: 'horizontal',
    loop: true,
    modules: [Navigation],
    navigation: {
        nextEl: '.swiper-gallery-button-next',
        prevEl: '.swiper-gallery-button-prev',
    },
});

// Add accordion component data
document.addEventListener('alpine:init', () => {
    Alpine.data('accordion', () => ({
        activePanels: ['panel1'], // Changed to array to track multiple panels
        toggle(panel) {
            if (this.activePanels.includes(panel)) {
                this.activePanels = this.activePanels.filter(p => p !== panel);
            } else {
                this.activePanels.push(panel);
            }
        }
    }));

    Alpine.store('mobileMenu', {
        isOpen: false,
        toggle() {
            this.isOpen = !this.isOpen;
        }
    })

    Alpine.data('divCollector', () => ({
        init() {
            // Get all elements marked for collection (using data-collect attribute)
            const sourceDivs = document.querySelectorAll('[data-collect]');

            // Create or get target container
            let targetContainer = document.querySelector('#collected-content');
            if (!targetContainer) {
                targetContainer = document.createElement('div');
                targetContainer.id = 'collected-content';
                document.body.appendChild(targetContainer);
            }

            // Sort elements by their order attribute
            const sortedDivs = Array.from(sourceDivs).sort((a, b) => {
                const orderA = parseInt(a.dataset.order) || 0;
                const orderB = parseInt(b.dataset.order) || 0;
                return orderA - orderB;
            });

            // Clone and append each div
            sortedDivs.forEach(div => {
                const clone = div.cloneNode(true);
                targetContainer.appendChild(clone);
            });
        }
    }));

    Alpine.data('csrfLoader', () => ({
        init() {
            // Find the corresponding Form
            const form = document.querySelector('#contact-form');

            // Using fetch instead of jQuery ajax
            fetch('/dynamic', {
                method: 'GET',
            })
                .then(response => response.json())
                .then(data => {
                    // Locate and update the CSRF input
                    const csrf = data.csrf;
                    form.querySelector(`input[name="${csrf.name}"]`).value = csrf.value;
                })
                .catch(error => console.error('Error loading CSRF:', error));
        }
    }));

    Alpine.data('youtubeEmbed', () => ({
        init() {
            // Find all oembed elements
            const oembedElements = document.querySelectorAll('oembed[url]');

            oembedElements.forEach(element => {
                const url = element.getAttribute('url');
                const videoId = this.extractYouTubeId(url);

                if (videoId) {
                    const iframe = document.createElement('iframe');
                    iframe.setAttribute('src', `https://www.youtube.com/embed/${videoId}`);
                    iframe.setAttribute('width', '560');
                    iframe.setAttribute('height', '315');
                    iframe.setAttribute('frameborder', '0');
                    iframe.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture');
                    iframe.setAttribute('allowfullscreen', 'true');
                    iframe.title = "YouTube video player";
                    iframe.loading = "lazy";
                    iframe.style.aspectRatio = "16/9";
                    iframe.style.width = "100%";
                    iframe.style.display = "block";
                    iframe.style.height = "auto";
                    iframe.style.margin = "1.5rem auto";

                    if (element.parentNode) {
                        element.parentNode.replaceChild(iframe, element);
                    }
                }
            });
        },

        extractYouTubeId(url) {
            if (!url) return null;
            const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
            const matches = url.match(regex);
            return matches ? matches[1] : null;
        }
    }));
});

// Global
window.Alpine = Alpine;

// Inits
Alpine.start();

